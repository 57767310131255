/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { translateStatic } from "../../helpers/translationHelper";
import {
  getTranslatedHeadline, 
  getTranslatedDescription
} from "../../helpers/propertyHelper";
import * as theme5Styles from "../theme5/scss/theme5.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/building/BasicInformation";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Contact from "./components/Contact";
import Layouts from "./detail/Layouts";
import PropertyList from "./components/property-list";
import OpenStreetMap from "../../common/OpenStreetMap";

const Theme5BuildingDetails = (props) => {
  const { pageContext, result } = props;
  const { building, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: { name: siteName, setting: display_settings },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_project_layouts,
    display_project_listings,
    display_project_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(building, locale, defaultLocale);
  const description = getTranslatedDescription(building, locale, defaultLocale);

  let seoTitle = `${building.name}`;
  if (headline) {
    seoTitle = `${headline}-${building.name}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />

      <div
        className={`${theme5Styles.mainContent} ${theme5Styles.pb0} ${theme5Styles.pt0}`}
      >
        <div className={theme5Styles.wrapper}>
          <div className={`${theme5Styles.prodDetailsHead}`}>
            <div>
              <h2>{building.name}</h2>
              <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <span className={theme5Styles.location}>{building.location}</span>
            </div>
          </div>

          <div className={`${theme5Styles.detailsImages}`}>
            {(() => {
              if (building.pictures.length > 0) {
                return (
                  <Media
                    pictures={building.pictures}
                  />
                );
              }
            })()}
          </div>

          <div className={`${theme5Styles.propDetailsSec}`}>
            <h3>{translateStatic("details", locale, defaultLocale)}</h3>

            <BasicInformation
              building={building}
              locale={locale}
              defaultLocale={defaultLocale}
            />

            {description.trim() && <p dangerouslySetInnerHTML={{ __html: description }}></p>}
          </div>

          {(() => {
            if (building.services.length > 0 || building.amenities.length > 0) {
              return (
                <div className={`${theme5Styles.propDetailsSec}`}>
                  <Feature
                    services={building.services}
                    amenities={building.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.videos.length > 0) {
              return (
                <div className={`${theme5Styles.propVideoSec}`}>
                  <Video
                    videos={building.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.documents.length > 0) {
              return (
                <div className={`${theme5Styles.propDetailsSec}`}>
                  <Document
                    documents={building.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_layouts && building.layouts.length > 0) {
              return (
                <div className={theme5Styles.propDetailsSec}>
                  <Layouts
                    layouts={building.layouts}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_listings && building.properties.length > 0) {
              return (
                <div className={theme5Styles.propDetailsSec}>
                  <h3>
                    {translateStatic("properties", locale, defaultLocale)}
                  </h3>
                  <PropertyList
                    properties={building.properties.slice(0, 3)}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <div
                    className={`${theme5Styles.mt16} ${theme5Styles.textRight}`}
                  >
                    <Link
                      to={`/${locale}/building/${building.uuid}/properties`}
                      className={`${theme5Styles.btn} ${theme5Styles.btnPrimary} ${theme5Styles.btnSm}`}
                    >
                      {translateStatic(
                        "view_all_properties",
                        locale,
                        defaultLocale
                      )}
                    </Link>
                  </div>
                </div>
              );
            }
          })()}

          {display_project_location_map && building.latitude && building.longitude && 
          <div className={`${theme5Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="building" 
              listings={[building]} 
              latitude={parseFloat(building.latitude)} 
              longitude={parseFloat(building.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}

          <section
            className={`${theme5Styles.homeContainet} ${theme5Styles.py40} ${theme5Styles.mt40}`}
          >
            <h2 className={`${theme5Styles.secTl}`}>
              {translateStatic("contact", locale, defaultLocale)}
            </h2>
            <Contact />
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default Theme5BuildingDetails;

Theme5BuildingDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
};
