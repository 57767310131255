import React, { useState } from "react";
import PropTypes from "prop-types";
import FsLightbox from "fslightbox-react";

import { translateStatic } from "../../../helpers/translationHelper";
import * as theme4Styles from "../scss/theme4.module.scss";

import closeIcon from "../images/close.png";

const LayoutModal = (props) => {
  const {
    isOpen,
    closeModal,
    layout,
    locale,
    defaultLocale,
    watermark,
    website,
  } = props;
  const {
    internal_size,
    internal_area_measurement_unit,
    total_size,
    total_area_measurement_unit,
  } = layout;

  const internal_area = `${internal_size} ${
    internal_area_measurement_unit ? internal_area_measurement_unit.name : ""
  }`;
  const total_area = `${total_size} ${
    total_area_measurement_unit ? total_area_measurement_unit.name : ""
  }`;

  const [toggler, setToggler] = useState(false);
  const [slide, setSlide] = useState(0);

  const handleImageOnClick = (toggler, slide) => {
    setToggler(!toggler);
    setSlide(slide);
  };

  let sources = [];
  const picture = layout.layout[0] || null;
  if (picture) {
    sources.push(watermark ? `${picture.watermark_url}?website=${website}` : picture.image_url);
  }

  const pictures = layout.pictures;
  sources = [
    ...sources,
    ...pictures.map((picture) =>
      watermark ? `${picture.watermark_url}?website=${website}` : picture.image_url
    ),
  ];

  return (
    <div
      className={`${theme4Styles.layoutModal} ${
        isOpen ? `${theme4Styles.show}` : ` `
      }`}
    >
      <div className={`${theme4Styles.modal}`}>
        <div className={`${theme4Styles.modalHeader}`}>
          <span className={`${theme4Styles.close}`} onClick={closeModal}>
            <img alt="" src={closeIcon} />
          </span>
          <h2 className={`${theme4Styles.modalTitle}`}>{layout.name}</h2>
        </div>
        <div className={`${theme4Styles.modalBody}`}>
          <div className={`${theme4Styles.modalBox}`}>
            <div className={`${theme4Styles.basicInfo}`}>
              <table className={`${theme4Styles.table}`}>
                <tbody>
                  <tr>
                    <td>{translateStatic("name", locale, defaultLocale)}</td>
                    <td>{layout.name}</td>
                  </tr>
                  <tr>
                    <td>
                      {translateStatic("reference", locale, defaultLocale)}
                    </td>
                    <td>{layout.reference}</td>
                  </tr>
                  <tr>
                    <td>{translateStatic("bedroom", locale, defaultLocale)}</td>
                    <td>{layout.bedroom}</td>
                  </tr>
                  <tr>
                    <td>
                      {translateStatic("bathroom", locale, defaultLocale)}
                    </td>
                    <td>{layout.bathroom}</td>
                  </tr>
                  <tr>
                    <td>
                      {translateStatic("internal_size", locale, defaultLocale)}
                    </td>
                    <td>{internal_area}</td>
                  </tr>
                  <tr>
                    <td>
                      {translateStatic("total_size", locale, defaultLocale)}
                    </td>
                    <td>{total_area}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {(() => {
              if (picture || pictures.length > 0) {
                return (
                  <FsLightbox
                    type="image"
                    toggler={toggler}
                    slide={slide}
                    sources={sources}
                  />
                );
              }
            })()}

            {(() => {
              if (picture) {
                const picture_url = picture.image_url;

                return (
                  <div className={`${theme4Styles.dataC}`}>
                    <h6>{translateStatic("layout", locale, defaultLocale)}</h6>
                    <div className={`${theme4Styles.layoutRow}`}>
                      <div className={`${theme4Styles.colm}`}>
                        <div
                          className={`${theme4Styles.image}`}
                          onClick={() => handleImageOnClick(toggler, 1)}
                        >
                          <img alt="" src={picture_url} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })()}

            {(() => {
              if (pictures.length > 0) {
                const index = picture ? 1 : 0;

                return (
                  <div className={`${theme4Styles.dataC}`}>
                    <h6>
                      {translateStatic("pictures", locale, defaultLocale)}
                    </h6>
                    <div className={`${theme4Styles.layoutRow}`}>
                      {pictures.map((img, i) => {
                        const img_url = img.image_url;

                        return (
                          <div className={`${theme4Styles.colm}`} key={i}>
                            <a
                              className={`${theme4Styles.image}`}
                              onClick={() =>
                                handleImageOnClick(toggler, i + 1 + index)
                              }
                            >
                              <img alt="" src={img_url} />
                            </a>
                            <span className={`${theme4Styles.name}`}>
                              {img.title}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
};

LayoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  layout: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  watermark: PropTypes.bool.isRequired,
  website: PropTypes.number.isRequired,
};

export default LayoutModal;
