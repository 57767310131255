exports.calculateBedroomsRange = (layouts = []) => {
  if (layouts.length == 0) {
    return 0;
  }

  let min = Math.min(...layouts.map(layout => layout.bedroom));

  let max = Math.max(...layouts.map(layout => layout.bedroom));
  
  return `${min}-${max}`;
};

exports.calculateBathroomsRange = (layouts = []) => {
  if (layouts.length == 0) {
    return 0;
  }

  let min = Math.min(...layouts.map(layout => layout.bathroom));

  let max = Math.max(...layouts.map(layout => layout.bathroom));
  
  return `${min}-${max}`;
};
