/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { translateStatic} from "../../helpers/translationHelper";
import { 
  getTranslatedHeadline, 
  getTranslatedDescription 
} from "../../helpers/propertyHelper";
import * as theme1Styles from "../theme1/scss/theme1.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/building/BasicInformation";
import Feature from "./detail/common/Feature";
import Image from "./detail/common/Image";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Layouts from "./detail/Layouts";
import PropertyList from "./components/property-list";
import OpenStreetMap from "../../common/OpenStreetMap";

const Theme1BuildingDetails = (props) => {
  const { pageContext, result } = props;
  const { building, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: { name: siteName, setting: display_settings },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_project_layouts,
    display_project_listings,
    display_project_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(building, locale, defaultLocale);
  const description = getTranslatedDescription(building, locale, defaultLocale);

  let seoTitle = `${building.name}`;
  if (headline) {
    seoTitle = `${headline}-${building.name}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />

      <div className={theme1Styles.detailsHeaderC}>
        <div className={theme1Styles.wrapper}>
          <div className={`${theme1Styles.detailsHeader}`}>
            <div className={`${theme1Styles.hLeft}`}>
              <h2>{building.name}</h2>
              <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
              <span className={theme1Styles.loc}>{building.location}</span>
            </div>
          </div>
        </div>
      </div>

      {(() => {
        if (building.pictures.length > 0) {
          return (
            <Media pictures={building.pictures} />
          );
        }
      })()}

      <div className={`${theme1Styles.mainContent}`}>
        <div className={theme1Styles.wrapper}>
          <BasicInformation 
            building={building} 
            locale={locale} 
            defaultLocale={defaultLocale}
          />
          
          {(() => {
            if (building.services.length > 0 || building.amenities.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Feature
                    services={building.services}
                    amenities={building.amenities}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {description.trim() && <div className={`${theme1Styles.propDetailsSec}`}>
            <h3>
              {translateStatic("description", locale, defaultLocale)}
            </h3>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>}

          {(() => {
            if (building.pictures.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Image
                    pictures={building.pictures}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.videos.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Video
                    videos={building.videos}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (building.documents.length > 0) {
              return (
                <div className={`${theme1Styles.propDetailsSec}`}>
                  <Document
                    documents={building.documents}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_layouts && building.layouts.length > 0) {
              return (
                <div className={theme1Styles.propDetailsSec}>
                  <Layouts
                    layouts={building.layouts}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                </div>
              );
            }
          })()}

          {(() => {
            if (display_project_listings && building.properties.length > 0) {
              return (
                <div className={theme1Styles.propDetailsSec}>
                  <h3>
                    {translateStatic("properties", locale, defaultLocale)}
                  </h3>
                  <PropertyList
                    properties={building.properties.slice(0, 4)}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <div
                    className={`${theme1Styles.mt16} ${theme1Styles.textRight}`}
                  >
                    <Link
                      to={`/${locale}/building/${building.uuid}/properties`}
                      className={`${theme1Styles.btn} ${theme1Styles.btnPrimary} ${theme1Styles.btnSm}`}
                    >
                      {translateStatic(
                        "view_all_properties",
                        locale,
                        defaultLocale
                      )}
                    </Link>
                  </div>
                </div>
              );
            }
          })()}

          {display_project_location_map && building.latitude && building.longitude && 
          <div className={`${theme1Styles.propDetailsSec}`} style={{ height: '300px' }}>
            <OpenStreetMap 
              listingType="building" 
              listings={[building]} 
              latitude={parseFloat(building.latitude)} 
              longitude={parseFloat(building.longitude)} 
              zoom={12} 
              infoWindow={false} 
              moveEvent={false}
            />
          </div>}
        </div>
      </div>
    </Layout>
  );
};

export default Theme1BuildingDetails;

Theme1BuildingDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
