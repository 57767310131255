import React, { useState, useContext } from "react";
import PropTypes from "prop-types";

import LayoutModal from "./LayoutModal";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import { translateStatic } from "../../../helpers/translationHelper";

import * as theme2Styles from "../scss/theme2.module.scss";

import layout1 from "../images/layout-image-1.jpg";

const Layouts = ({ layouts, locale, defaultLocale }) => {
  const [selectedLayout, setLayout] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          id: website_id,
          setting: {
            display_watermark
          }
        }
      }
    }
  } = siteData;

  const openModal = (layout) => {
    setLayout(layout);
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setLayout(null);
    setIsOpenModal(false);
  };

  return (
    <React.Fragment>
      <h3>{translateStatic("layouts", locale, defaultLocale)}</h3>
      <div className={theme2Styles.layoutTable}>
        <table className={`${theme2Styles.table}`}>
          <thead>
            <tr>
              <th>{translateStatic("layout", locale, defaultLocale)}</th>
              <th>{translateStatic("name", locale, defaultLocale)}</th>
              <th>{translateStatic("reference", locale, defaultLocale)}</th>
              <th>{translateStatic("bedrooms", locale, defaultLocale)}</th>
              <th>{translateStatic("bathrooms", locale, defaultLocale)}</th>
              <th>{translateStatic("internal_size", locale, defaultLocale)}</th>
              <th>{translateStatic("total_size", locale, defaultLocale)}</th>
            </tr>
          </thead>
          <tbody>
            {layouts.map((unit, index) => {
              const {
                name,
                reference,
                bedroom,
                bathroom,
                internal_size,
                total_size,
                internal_area_measurement_unit,
                total_area_measurement_unit,
                layout,
              } = unit;

              const internal_area = `${internal_size} ${
                internal_area_measurement_unit
                  ? internal_area_measurement_unit.name
                  : ""
              }`;
              const total_area = `${total_size} ${
                total_area_measurement_unit
                  ? total_area_measurement_unit.name
                  : ""
              }`;

              const img = layout[0] ? layout[0].image_url : layout1;

              return (
                <tr key={index}>
                  <td>
                    <a
                      className={theme2Styles.propImg}
                      onClick={() => openModal(unit)}
                    >
                      <img src={img} alt="" />
                    </a>
                  </td>
                  <td data-th="Name">{name}</td>
                  <td data-th="Reference">{reference}</td>
                  <td data-th="Bedrooms">{bedroom}</td>
                  <td data-th="Bathrooms">{bathroom}</td>
                  <td data-th="Internal Size">{internal_area}</td>
                  <td data-th="Total Size">{total_area}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isOpenModal && selectedLayout && (
        <LayoutModal
          isOpen={isOpenModal}
          closeModal={closeModal}
          layout={selectedLayout}
          locale={locale}
          defaultLocale={defaultLocale}
          watermark={`${display_watermark != 0}`}
          website={website_id}
        />
      )}
    </React.Fragment>
  );
};

Layouts.propTypes = {
  layouts: PropTypes.array.isRequired,
  locale: PropTypes.string,
  defaultLocale: PropTypes.string
};

export default Layouts;
