import React from "react";
import PropTypes from "prop-types";

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme5Styles from "../../scss/theme5.module.scss";

const BasicInformation = ({ building, locale, defaultLocale }) => {
    const { measurement_unit } = building;

    return (
        <div className={`${theme5Styles.propertyInfos}`}>
            <div className={`${theme5Styles.infoRow}`}>
                {(() => {
                    if (!building.property_type) return null;

                    const propertyType = getTranslatedValue(
                        building.property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (propertyType) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("property_type", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {propertyType}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {(() => {
                    if (!building.tenure) return null;

                    const tenure = getTranslatedValue(
                        building.tenure.translations,
                        "name",
                        locale,
                        defaultLocale
                    );
                    
                    if (tenure) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("tenure", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {tenure}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {(() => {
                    if (!building.status) return null;

                    const status = getTranslatedValue(
                        building.status.translations,
                        "name",
                        locale,
                        defaultLocale
                    );

                    if (status) {
                        return (
                            <div className={`${theme5Styles.colm}`}>
                                <div className={`${theme5Styles.infos}`}>
                                    <span className={`${theme5Styles.tl}`}>
                                        {translateStatic("status", locale, defaultLocale)}:
                                    </span>
                                    <span className={`${theme5Styles.desc}`}>
                                        {status}
                                    </span>
                                </div>
                            </div>
                        );
                    }
                })()}

                {building.no_of_floors && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("total_floor", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.no_of_floors}
                        </span>
                    </div>
                </div>}

                {building.no_of_units && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("total_units", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.no_of_units}
                        </span>
                    </div>
                </div>}

                {building.completion_date && building.completion_date != '0000-00-00' && building.completion_date != '1970-01-01' && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("completion_date", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.completion_date}
                        </span>
                    </div>
                </div>}

                {building.city && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("city", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.city}
                        </span>
                    </div>
                </div>}

                {building.district && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("district", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.district}
                        </span>
                    </div>
                </div>}

                {building.state && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("state", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.state}
                        </span>
                    </div>
                </div>}

                {building.country && building.country.country_name && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("country", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.country.country_name}
                        </span>
                    </div>
                </div>}

                {building.built_in_area_value && measurement_unit && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("built_area", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.built_in_area_value + ' ' + (measurement_unit.name || '')}
                        </span>
                    </div>
                </div>}

                {building.land_area_value && measurement_unit && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("land_area", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.land_area_value + ' ' + (measurement_unit.name || '')}
                        </span>
                    </div>
                </div>}

                {building.permit_number && <div className={`${theme5Styles.colm}`}>
                    <div className={`${theme5Styles.infos}`}>
                        <span className={`${theme5Styles.tl}`}>
                            {translateStatic("permit_number", locale, defaultLocale)}:
                        </span>
                        <span className={`${theme5Styles.desc}`}>
                            {building.permit_number}
                        </span>
                    </div>
                </div>}
            </div>
        </div>
    );
};

BasicInformation.propTypes = {
    building: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;