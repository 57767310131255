/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";
import Layout from "./components/layout";
import SEO from "./components/seo";
import { translateStatic } from "../../helpers/translationHelper";
import {
  calculateBedroomsRange,
  calculateBathroomsRange,
} from "../../helpers/buildingHelper";
import {
  getTranslatedHeadline, 
  getTranslatedDescription
} from "../../helpers/propertyHelper";
import * as theme4Styles from "./scss/theme4.module.scss";

import Media from "./detail/common/Media";
import BasicInformation from "./detail/building/BasicInformation";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Layouts from "./detail/Layouts";
import PropertyList from "./components/property-list";
import OpenStreetMap from "../../common/OpenStreetMap";

import profilePic from "./images/profile-pic.jpg";

const Theme4BuildingDetails = (props) => {
  const { pageContext, result } = props;
  const { building, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          webform_assigned_to_user,
          webform_assigned_to_group,
          setting: display_settings,
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_project_layouts,
    display_project_listings,
    display_project_assigned_to_detail,
    display_project_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(building, locale, defaultLocale);
  const description = getTranslatedDescription(building, locale, defaultLocale);

  let seoTitle = `${building.name}`;
  if (headline) {
    seoTitle = `${headline}-${building.name}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />
      <div className={`${theme4Styles.mainContent} ${theme4Styles.pt0}`}>
        <div className={theme4Styles.wrapper}>
          <div className={`${theme4Styles.prodDetailsHead}`}>
            <div className={`${theme4Styles.leftSec}`}>
              <h2>{building.name}</h2>
              <span className={theme4Styles.location}>{building.location}</span>
            </div>
            <div className={`${theme4Styles.rightSec}`}>
              {/* <div className={`${theme4Styles.prop}`}></div> */}
              <div className={`${theme4Styles.propInfos}`}>
                <div className={`${theme4Styles.infos}`}>
                  {calculateBedroomsRange(building.layouts)}
                  <span>Beds</span>
                </div>
                <div className={`${theme4Styles.infos}`}>
                  {calculateBathroomsRange(building.layouts)}
                  <span>Bath</span>
                </div>
                <div className={`${theme4Styles.infos}`}>
                  <div className={`${theme4Styles.sqft}`}>
                    {(() => {
                      if (
                        building.internal_area_value &&
                        building.measurement_unit
                      ) {
                        return (
                          <span>
                            {building.internal_area_value}{" "}
                            {building.measurement_unit.name}
                          </span>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${theme4Styles.detailsPageRow}`}>
            <div className={`${theme4Styles.detailsColmL}`}>
              {(() => {
                if (building.pictures.length > 0) {
                  return (
                    <Media
                      pictures={building.pictures}
                    />
                  );
                }
              })()}

              {(headline.trim() || description.trim()) && <div className={`${theme4Styles.propDetailsSec}`}>
                <h2 dangerouslySetInnerHTML={{ __html: headline }}></h2>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
              </div>}

              {(() => {
                if (
                  building.services.length > 0 ||
                  building.amenities.length > 0
                ) {
                  return (
                    <div className={theme4Styles.propDetailsSec}>
                      <Feature
                        services={building.services}
                        amenities={building.amenities}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (building.videos.length > 0) {
                  return (
                    <div
                      className={`${theme4Styles.propVideoSec} ${theme4Styles.propVideoSec}`}
                    >
                      <Video
                        videos={building.videos}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (building.documents.length > 0) {
                  return (
                    <div className={`${theme4Styles.propDetailsSec}`}>
                      <Document
                        documents={building.documents}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (display_project_layouts && building.layouts.length > 0) {
                  return (
                    <div className={theme4Styles.propDetailsSec}>
                      <Layouts
                        layouts={building.layouts}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {display_project_location_map && building.latitude && building.longitude && 
              <div className={`${theme4Styles.propDetailsSec}`} style={{ height: '300px' }}>
                <OpenStreetMap 
                  listingType="building" 
                  listings={[building]} 
                  latitude={parseFloat(building.latitude)} 
                  longitude={parseFloat(building.longitude)} 
                  zoom={12} 
                  infoWindow={false} 
                  moveEvent={false}
                />
              </div>}
            </div>
            <div className={`${theme4Styles.detailsColmR}`}>
              <BasicInformation
                building={building}
                locale={locale}
                defaultLocale={defaultLocale}
              />
              
              <div className={`${theme4Styles.descSec} ${theme4Styles.sticky}`}>
                {(() => {
                  let agent =
                    building.agent ||
                    webform_assigned_to_user ||
                    webform_assigned_to_group?.leader;
                  if (agent && display_project_assigned_to_detail) {
                    let phone_number = "";
                    phone_number += agent.countryCode
                      ? agent.countryCode + "-"
                      : "";
                    phone_number += agent.mobile_phone
                      ? agent.mobile_phone
                      : "";

                    return (
                      <React.Fragment>
                        <h3>
                          {translateStatic("agent", locale, defaultLocale)}
                        </h3>
                        <div
                          className={`${theme4Styles.contactAgent} ${theme4Styles.mt0}`}
                        >
                          <div className={theme4Styles.photo}>
                            <img
                              alt="phone"
                              src={agent.profile_image || profilePic}
                            />
                          </div>
                          <div className={theme4Styles.details}>
                            <span className={theme4Styles.nm}>
                              {agent.name}
                            </span>
                            <span className={theme4Styles.txt}>
                              <strong>
                                {translateStatic(
                                  "email",
                                  locale,
                                  defaultLocale
                                )}
                                :{" "}
                              </strong>
                              {agent.email}
                            </span>
                            <span className={theme4Styles.txt}>
                              <strong>
                                {translateStatic(
                                  "phone",
                                  locale,
                                  defaultLocale
                                )}
                                :{" "}
                              </strong>
                              {phone_number}
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                })()}

                <div className={theme4Styles.contactUs}>
                  <h6>
                    {translateStatic("contact_us", locale, defaultLocale)}
                    <small>
                      {translateStatic(
                        "contact_form_text",
                        locale,
                        defaultLocale
                      )}
                    </small>
                  </h6>
                  <ContactFormContainer />
                </div>
              </div>
            </div>
          </div>

          {(() => {
            if (display_project_listings && building.properties.length > 0) {
              return (
                <section
                  className={`${theme4Styles.homeContainet} ${theme4Styles.pb0}`}
                >
                  <h2 className={`${theme4Styles.secTl}`}>
                    {translateStatic("properties", locale, defaultLocale)}
                  </h2>
                  <PropertyList
                    properties={building.properties.slice(0, 4)}
                    locale={locale}
                    defaultLocale={defaultLocale}
                  />
                  <div
                    className={`${theme4Styles.mt16} ${theme4Styles.textRight}`}
                  >
                    <Link
                      to={`/${locale}/building/${building.uuid}/properties`}
                      className={`${theme4Styles.btn} ${theme4Styles.btnPrimary} ${theme4Styles.btnSm}`}
                    >
                      {translateStatic(
                        "view_all_properties",
                        locale,
                        defaultLocale
                      )}
                    </Link>
                  </div>
                </section>
              );
            }
          })()}
        </div>
      </div>
    </Layout>
  );
};

export default Theme4BuildingDetails;

Theme4BuildingDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
