/* eslint react/prop-types: 0 */
import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import ContactFormContainer from "../../common/ContactFormContainer";

import Layout from "./components/layout";
import SEO from "./components/seo";
import { 
  getTranslatedValue, 
  translateStatic 
} from "../../helpers/translationHelper";
import { 
  getTranslatedHeadline, 
  getTranslatedDescription 
} from "../../helpers/propertyHelper";
import * as theme3Styles from "../theme3/scss/theme3.module.scss";

import Media from "./detail/common/Media";
import Feature from "./detail/common/Feature";
import Document from "./detail/common/Document";
import Video from "./detail/common/Video";
import Layouts from "./detail/Layouts";
import PropertyList from "./components/property-list";
import OpenStreetMap from "../../common/OpenStreetMap";

import profilePic from "./images/profile-pic.jpg";

const Theme3BuildingDetails = (props) => {
  const { pageContext, result } = props;
  const { building, locale } = pageContext;
  const {
    remarso: {
      domainByURL: {
        website: {
          name: siteName,
          webform_assigned_to_user,
          webform_assigned_to_group,
          setting: display_settings,
        },
      },
    },
    defaultLocaleKey: defaultLocale,
  } = result;

  const {
    display_project_layouts,
    display_project_listings,
    display_project_assigned_to_detail,
    display_project_location_map,
  } = display_settings;

  const headline = getTranslatedHeadline(building, locale, defaultLocale);
  const description = getTranslatedDescription(building, locale, defaultLocale);

  let seoTitle = `${building.name}`;
  if (headline) {
    seoTitle = `${headline}-${building.name}`;
  }

  let metaTitle = '';
  if (headline) {
    metaTitle = `${headline}-${siteName}`;
  }

  return (
    <Layout>
      <SEO title={seoTitle} metaTitle={metaTitle} />
      <div className={`${theme3Styles.mainContent} ${theme3Styles.pt0}`}>
        <div className={theme3Styles.wrapper}>
          <div className={`${theme3Styles.detailsPageRow}`}>
            <div className={`${theme3Styles.detailsColmL}`}>
              {(() => {
                if (building.pictures.length > 0) {
                  return (
                    <Media
                      pictures={building.pictures}
                    />
                  );
                }
              })()}

              <div className={`${theme3Styles.detailsHeader}`}>
                <h2>{building.name}</h2>
                <span className={theme3Styles.loc}>{building.location}</span>
                {(() => {
                  const propertyType = building.property_type
                    ? getTranslatedValue(
                        building.property_type.translations,
                        "name",
                        locale,
                        defaultLocale
                      )
                    : null;

                  if (propertyType) {
                    return (
                      <span className={theme3Styles.category}>
                        {propertyType}
                      </span>
                    );
                  }
                })()}
              </div>
              {(() => {
                if (
                  building.services.length > 0 ||
                  building.amenities.length > 0
                ) {
                  return (
                    <div className={theme3Styles.propDetailsSec}>
                      <Feature
                        services={building.services}
                        amenities={building.amenities}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(headline.trim() || description.trim()) && <div className={`${theme3Styles.propDetailsSec}`}>
                <h3 dangerouslySetInnerHTML={{ __html: headline }}></h3>
                <p dangerouslySetInnerHTML={{ __html: description }}></p>
              </div>}

              {(() => {
                if (building.videos.length > 0) {
                  return (
                    <div
                      className={`${theme3Styles.propDetailsSec} ${theme3Styles.propVideoSec}`}
                    >
                      <Video
                        videos={building.videos}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (building.documents.length > 0) {
                  return (
                    <div className={`${theme3Styles.propDetailsSec}`}>
                      <Document
                        documents={building.documents}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (display_project_layouts && building.layouts.length > 0) {
                  return (
                    <div className={theme3Styles.propDetailsSec}>
                      <Layouts
                        layouts={building.layouts}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                    </div>
                  );
                }
              })()}

              {(() => {
                if (
                  display_project_listings &&
                  building.properties.length > 0
                ) {
                  return (
                    <div className={theme3Styles.propDetailsSec}>
                      <h3>
                        {translateStatic("properties", locale, defaultLocale)}
                      </h3>
                      <PropertyList
                        properties={building.properties.slice(0, 4)}
                        locale={locale}
                        defaultLocale={defaultLocale}
                      />
                      <div
                        className={`${theme3Styles.mt16} ${theme3Styles.textRight}`}
                      >
                        <Link
                          to={`/${locale}/building/${building.uuid}/properties`}
                          className={`${theme3Styles.btn} ${theme3Styles.btnPrimary} ${theme3Styles.btnSm}`}
                        >
                          {translateStatic(
                            "view_all_properties",
                            locale,
                            defaultLocale
                          )}
                        </Link>
                      </div>
                    </div>
                  );
                }
              })()}

              {display_project_location_map && building.latitude && building.longitude && 
              <div className={`${theme3Styles.propDetailsSec}`} style={{ height: '300px' }}>
                <OpenStreetMap 
                  listingType="building" 
                  listings={[building]} 
                  latitude={parseFloat(building.latitude)} 
                  longitude={parseFloat(building.longitude)} 
                  zoom={12} 
                  infoWindow={false} 
                  moveEvent={false}
                />
              </div>}
            </div>
            <div className={`${theme3Styles.detailsColmR}`}>
              <div className={theme3Styles.contactUs}>
                <h6>
                  {translateStatic("contact_us", locale, defaultLocale)}
                  <small>
                    {translateStatic(
                      "contact_form_text",
                      locale,
                      defaultLocale
                    )}
                  </small>
                </h6>
                <ContactFormContainer />
                {(() => {
                  let agent =
                    building.agent ||
                    webform_assigned_to_user ||
                    webform_assigned_to_group?.leader;
                  if (display_project_assigned_to_detail && agent) {
                    let phone_number = "";
                    phone_number += agent.countryCode
                      ? agent.countryCode + "-"
                      : "";
                    phone_number += agent.mobile_phone
                      ? agent.mobile_phone
                      : "";
                    return (
                      <div className={`${theme3Styles.contactAgent}`}>
                        <div className={theme3Styles.photo}>
                          <img
                            alt="phone"
                            src={agent.profile_image || profilePic}
                          />
                        </div>
                        <div className={theme3Styles.details}>
                          <span className={theme3Styles.nm}>{agent.name}</span>
                          <span className={theme3Styles.txt}>
                            <strong>
                              {translateStatic("email", locale, defaultLocale)}:{" "}
                            </strong>
                            {agent.email}
                          </span>
                          <span className={theme3Styles.txt}>
                            <strong>
                              {translateStatic("phone", locale, defaultLocale)}:{" "}
                            </strong>
                            {phone_number}
                          </span>
                        </div>
                      </div>
                    );
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Theme3BuildingDetails;

Theme3BuildingDetails.propTypes = {
  pageContext: PropTypes.object.isRequired,
  result: PropTypes.object,
};
