import React from "react";
import PropTypes from "prop-types";

import WithThemeLoading from "../hoc/withThemeLoading";
import Theme1BuildingDetails from "../themes/theme1/theme1BuildingDetails";
import Theme2BuildingDetails from "../themes/theme2/theme2BuildingDetails";
import Theme3BuildingDetails from "../themes/theme3/theme3BuildingDetails";
import Theme4BuildingDetails from "../themes/theme4/theme4BuildingDetails";
import Theme5BuildingDetails from "../themes/theme5/theme5BuildingDetails";

const BuildingDetail = (props) => {
  /** Begin: Theme Switcher */
  const templateId =
    process.env.GATSBY_TEST_THEME === "0"
      ? props.pageContext.template.id
      : process.env.GATSBY_TEST_THEME;
  const themes = [
    Theme1BuildingDetails,
    Theme2BuildingDetails,
    Theme3BuildingDetails,
    Theme4BuildingDetails,
    Theme5BuildingDetails,
  ];
  const ThemeLoading =
    parseInt(templateId) > 0
      ? WithThemeLoading(themes[parseInt(templateId) - 1])
      : WithThemeLoading(Theme1BuildingDetails);
  /** End: Theme Switcher */

  return <ThemeLoading {...props} />;
};

BuildingDetail.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default BuildingDetail;
