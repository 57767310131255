import React from "react";
import PropTypes from "prop-types";

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme4Styles from "../../scss/theme4.module.scss";

const BasicInformation = ({ building, locale, defaultLocale }) => {
    return (
        <table className={`${theme4Styles.table} ${theme4Styles.infoTable}`}>
            {building.published_date && <tr>
                <td>{translateStatic("published_date", locale, defaultLocale)}</td>
                <td>{building.published_date}</td>    
            </tr>}

            {(() => {
                if (!building.property_type) return null;

                const propertyType = getTranslatedValue(
                    building.property_type.translations,
                    "name",
                    locale,
                    defaultLocale
                );

                if (propertyType) {
                    return (
                        <tr>
                            <td>{translateStatic("property_type", locale, defaultLocale)}</td>
                            <td>{propertyType}</td>
                        </tr>
                    );
                }
            })()}

            {(() => {
                if (!building.tenure) return null;

                const tenure = getTranslatedValue(
                    building.tenure.translations,
                    "name",
                    locale,
                    defaultLocale
                );
                
                if (tenure) {
                    return (
                        <tr>
                            <td>{translateStatic("tenure", locale, defaultLocale)}</td>
                            <td>{tenure}</td>
                        </tr>
                    );
                }
            })()}

            {(() => {
                if (!building.status) return null;

                const status = getTranslatedValue(
                    building.status.translations,
                    "name",
                    locale,
                    defaultLocale
                );

                if (status) {
                    return (
                        <tr>
                            <td>{translateStatic("status", locale, defaultLocale)}</td>
                            <td>{status}</td>
                        </tr>
                    );
                }
            })()}

            {(() => {
                if (!building.range[0]) return null;

                const range = getTranslatedValue(
                    building.range[0].translations,
                    "name",
                    locale,
                    defaultLocale
                );

                if (range) {
                    return (
                        <tr>
                            <td>{translateStatic("range", locale, defaultLocale)}</td>
                            <td>{range}</td>
                        </tr>
                    );
                }
            })()}

            {building.no_of_floors && <tr>
                <td>{translateStatic("total_floor", locale, defaultLocale)}</td>
                <td>{building.no_of_floors}</td>
            </tr>}

            {building.no_of_blocks && <tr>
                <td>{translateStatic("total_blocks", locale, defaultLocale)}</td>
                <td>{building.no_of_blocks}</td>
            </tr>}

            {building.city && <tr>
                <td>{translateStatic("city", locale, defaultLocale)}</td>
                <td>{building.city}</td>
            </tr>}

            {building.district && <tr>
                <td>{translateStatic("district", locale, defaultLocale)}</td>
                <td>{building.district}</td>
            </tr>}

            {building.state && <tr>
                <td>{translateStatic("state", locale, defaultLocale)}</td>
                <td>{building.state}</td>
            </tr>}

            {building.country && building.country.country_name && <tr>
                <td>{translateStatic("country", locale, defaultLocale)}</td>
                <td>{building.country.country_name}</td>
            </tr>}

            {building.built_in_area_value && building.measurement_unit && <tr>
                <td>{translateStatic("built_area", locale, defaultLocale)}</td>
                <td>{building.built_in_area_value + ' ' + (building.measurement_unit.name || '')}</td>
            </tr>}

            {building.land_area_value && building.measurement_unit && <tr>
                <td>{translateStatic("land_area", locale, defaultLocale)}</td>
                <td>{building.land_area_value + ' ' + (building.measurement_unit.name || '')}</td>
            </tr>}

            {building.permit_number && <tr>
                <td>{translateStatic("permit_number", locale, defaultLocale)}</td>
                <td>{building.permit_number}</td>    
            </tr>}
        </table>
    );
};

BasicInformation.propTypes = {
    building: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;