import React from "react";
import PropTypes from "prop-types"

import { getTranslatedValue, translateStatic } from "../../../../helpers/translationHelper";
import * as theme2Styles from "../../scss/theme2.module.scss";

import propertyTypeIcon from "../../../../images/property-type.svg";
import floorIcon from "../../../../images/building-floors.svg";
import blockIcon from "../../../../images/building-blocks.svg";
import unitIcon from "../../../../images/total.svg";
import calendarIcon from "../../../../images/calendar.svg";
import builtAreaIcon from "../../../../images/built-area.svg";
import landAreaIcon from "../../../../images/plans.png";
import tenureIcon from "../../../../images/house.svg";
import permitIcon from "../../../../images/permit.png";

const BasicInformation = ({ building, locale, defaultLocale }) => {
    return (
        <div className={`${theme2Styles.propDetailsSec}`}>
            <h3>Property Details</h3>
            <div className={`${theme2Styles.propDetailsFeatures}`}>
                <div className={`${theme2Styles.rw}`}>
                    {building.property_type && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={propertyTypeIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("property_type", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {getTranslatedValue(building.property_type.translations, 'name', locale, defaultLocale)}
                            </span>
                        </div>
                    </div>}


                    {building.tenure && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={tenureIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("tenure", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {getTranslatedValue(building.tenure.translations, 'name', locale, defaultLocale)}
                            </span>
                        </div>
                    </div>}

                    {(() => {
                        if (!building.status) return null;

                        const status = getTranslatedValue(
                            building.status.translations, 
                            'name', 
                            locale, 
                            defaultLocale
                        );

                        if (status) {
                            return (
                                <div className={theme2Styles.colm}>
                                    <div className={`${theme2Styles.feBox}`}>
                                        <img alt="image" src={tenureIcon} />
                                        <span className={theme2Styles.t}>
                                            {translateStatic("status", locale, defaultLocale)}
                                        </span>
                                        <span className={theme2Styles.n}>
                                            {status}
                                        </span>
                                    </div>
                                </div>
                            );
                        }
                    })()}
                    
                    {building.no_of_floors && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={floorIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("total_floor", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.no_of_floors}
                            </span>
                        </div>
                    </div>}

                    {building.no_of_blocks && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={blockIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("total_blocks", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.no_of_blocks}
                            </span>
                        </div>
                    </div>}

                    {building.no_of_units && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={unitIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("total_units", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.no_of_units}
                            </span>
                        </div>
                    </div>}

                    {building.completion_date && building.completion_date != '0000-00-00' && building.completion_date != '1970-01-01' && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={calendarIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("completion_date", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.completion_date}
                            </span>  
                        </div>              
                    </div>}

                    {building.built_in_area_value && building.measurement_unit && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={builtAreaIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("built_area", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.built_in_area_value + ' ' + (building.measurement_unit.name || '')}
                            </span>
                        </div>
                    </div>}

                    {building.land_area_value && building.measurement_unit && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={landAreaIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("land_area", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.land_area_value + ' ' + (building.measurement_unit.name || '')}
                            </span>
                        </div>
                    </div>}

                    {building.permit_number && <div className={theme2Styles.colm}>
                        <div className={`${theme2Styles.feBox}`}>
                            <img alt="image" src={permitIcon} />
                            <span className={theme2Styles.t}>
                                {translateStatic("permit_number", locale, defaultLocale)}
                            </span>
                            <span className={theme2Styles.n}>
                                {building.permit_number}
                            </span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

BasicInformation.propTypes = {
    building: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired
};

export default BasicInformation;
